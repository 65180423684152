import React from "react";

export default function Card(props){
    return (
        <div className="card--container">
            <img src = {props.img} alt="" className = {props.id === 1 ? "bird--image" : "people--image"}></img>
            {props.id === 1 ? <h4 className = "card--title-content">Nuestra <span>App</span></h4>
                 : <h4 className = "card--title-content">Quienes <span>somos</span>?</h4> }
            <p className = "card--info-content">{props.content}</p>
        </div>
    )
}