import React from "react";
import Logo from "../images/LOGO.png"

export default function Footer(){
    return (
        <div className = "footer--container">
            <footer>
                <img src = {Logo} alt = ""></img>
                <p className = "footer--title">2021 EcoEye</p>
                <p className = "mail--container">proyectoecoeye@gmail.com</p>
            </footer>    
        </div>
    );
}