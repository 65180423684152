import './style/App.css';
import Header from "./components/Header"
import Main from "./components/Main"
import Footer from "./components/Footer"
import React from "react"


function App() {

  return (
    <div className = "page--container">
        <Header/>
        <Main/>
        <Footer/>
    </div>
  )
}

export default App;

