import React from "react";
import Logo from "../images/LOGO.png";
import HomeImage from "../images/homeimage.png"
import Bird from "../images/Group.png" 
import People from "../images/ri_team-fill.png"
import Card from "./Card"

export default function Main() {
    return (
        <div className = "main--container">
            <img src = {HomeImage} alt = "homeimage" className = "main--homeimage"></img>
            <div className = "main--title-container">
                <img src = {Logo} alt = "logo" className = "main--logo"></img>
                <h1 className = "main--title">EcoEye</h1>
                <h3 className = "main--title-content"><span>Analizando</span> y <span>preservando</span> las aves <span>argentinas</span></h3>
                <button className = "main--button-toapp">Ir a la App</button>
            </div>
            <div className = "card--container-grid">
                <div className = "card--grid">
                    <Card 
                        img = {Bird}
                        id = {1}
                        content = {"EcoEye es un proyecto de reconocimiento de aves argentinas, ya sea por imagenes, o por su canto mediante Machine Learning. Buscamos informar sobre la fauna que nos rodea."}
                        className = "grid--item-left"
                    />
                    <Card 
                        img = {People}
                        id = {2}
                        content = {"Nuestro equipo esta conformado por estudiantes de cuarto año de la escuela ORT, con la iniciativa de aprender sobre las tecnologias exponenciales y su aplicacion en temas como el reconocimiento de especies. Conozcamosnos mas en esta pagina."}
                        className = "grid--item-right"
                    />
                </div>
            </div>
        </div>
    )
}