import React from "react"

export default function Header() {
    return (
        <div className = "header--container">
            <ul className = "header-list--container">
                <li className = "header--list-items">
                    <a href = " " className = "header--href">Inicio</a>
                </li>
                <li className = "header--list-items">
                    <a href = " " className = "header--href">App</a>
                </li>
                <li className = "header--list-items">
                    <a href = " " className = "header--href">Conocenos</a>
                </li>
                <li className = "header--list-items">
                    <a href = " " className = "header--href">Mapa</a>
                </li>
            </ul>
        </div>
    )
}